import React from "react";
import serviceS1 from "@/images/web.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Web Entwicklung</h2>
      <p>
        Bei Solxk widmen wir uns dem Entwerfen von faszinierendem und
        funktionalem Entwerfen Web -Erlebnisse, die genau auf die Erfüllung
        Ihrer Unterschiede zugeschnitten sind Geschäftsanforderungen.Unser Team
        erfahrener Entwickler, Designer, und Strategen arbeitet akribisch
        zusammen, um Ihre Vision zum Leben zu erwecken. Pixel von Pixel.Wir sind
        bestrebt, mehr als nur Websites zu erstellen.Wir Erstellen Sie digitale
        Erlebnisse, die sich engagieren, inspirieren und konvertieren.Unser Der
        Ansatz zur Webentwicklung integriert nahtlos innovative Spitzenreiter
        Technologie mit kreativer Innovation, um sicherzustellen, dass Ihre
        Online -Präsenz vorliegt Nicht nur auffällt, sondern liefert auch
        messbare Ergebnisse.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Planung und Strategie</h3>
          <p>Dienstleistungen, die wir anbieten:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Benutzerdefinierte
              Webentwicklung
            </li>
            <li>
              <i className="fa fa-check-square"></i>E-Commerce-Lösungen: Empower
              dein Geschäft
            </li>
            <li>
              <i className="fa fa-check-square"></i>Sich anpassendes Design
            </li>
            <li>
              <i className="fa fa-check-square"></i>Content -Management -Systeme
              (CMS)
            </li>
            <li>
              <i className="fa fa-check-square"></i>Entwicklung von
              Webanwendungen
            </li>
          </ul>
        </div>
      </div>
      <p>
        Egal, ob Sie ein Startup sind, das darauf abzielt, Ihren digitalen
        Fußabdruck zu etablieren oder Ein gut etabliertes Unternehmen, das Ihre
        Online-Strategie neu überarbeiten möchte. Solxk ist hier, um Ihnen zu
        helfen.Kontaktieren Sie uns noch heute, um Ihr Projekt zu besprechen und
        machen Sie den ersten Schritt, um das volle Potenzial Ihres
        Online-Präsenz.
      </p>
      <p>
        Bei Solxk erkennen wir an, dass die Grundlage eines erfolgreichen Webs
        Entwicklungsprojekt liegt in der akribischen Planung.Vor dem Schreiben
        einer Single Codelinie oder Entwerfen eines einzelnen Pixels investieren
        wir Zeit in die Zeit Verstehen Sie Ihre Geschäftsziele, Zielgruppen und
        einzigartig Anforderungen.Unser umfassender Planungsprozess sorgt dafür
        Aspekt Ihres Projekts wird sorgfältig berücksichtigt und mit ausgeführt
        Präzision.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
